import {
  RISK_SECTION_HIGH,
  RISK_SECTION_LOW,
  RISK_SECTION_MEDIUM,
} from '@consts/risk';
import {
  formatOption,
  formatQuestion,
  formatResult,
  formatRisk,
  formatYesNoQuestion,
} from '@/src/common/helpers/quiz';
import { COL_SIZE_FULL } from '@consts/quiz';

export const riskSettings = [
  formatRisk(RISK_SECTION_LOW, 'common.low', 'risk.low', 0, 3),
  formatRisk(RISK_SECTION_MEDIUM, 'common.medium', 'risk.medium', 4, 99, 0, 3),
  formatRisk(RISK_SECTION_HIGH, 'common.high', 'risk.high', 4, 99, 4, 99),
];

export const resultSettings = [
  formatResult(
    'With early treatment, you can speed up your recovery and reduce any risk of developing increased problems. At Joint Academy, you get assessment and treatment without the wait.',
    'Based on your answers, your lower back pain causes you mild discomfort in your everyday life. However, it’s always good to get an assessment of your pain in good time; the development of many joint pain conditions can be slowed down in an early stage, and much can be done to proactively reduce the risk of increased problems. The right treatment early on will also give you the best chance to maintain an active and pain-free life. Therefore, we recommend that you seek care for an assessment and treatment.<br/><br/>Today, exercise paired with education to help you better understand your condition, is recommended as the first-line treatment for lower back pain. This is the treatment that research shows provides the best results and pain relief, and is recommended by international guidelines.<br/><br/>With Joint Academy, you get a complete and clinically proven treatment straight to your phone. This means that you can start feeling better from the comfort of your home! When you start your treatment, you will connect with a personal physical therapist who will support you and be available on-demand throughout your treatment. Your physical therapist will help you get started with an exercise program tailored to reduce your lower back pain and restore mobility. Your exercises only take 5 minutes a day.',
    0,
    3,
  ),
  formatResult(
    'But don\'t worry! Treating your back pain in time will reduce the risk of long-term problems. At Joint Academy, you get the right treatment without the wait.',
    'Based on your answers, your lower back pain causes you moderate discomfort and appears to have a negative impact on your everyday life. With the right care, you can feel better and get back to doing what you love. Starting treatment early will avoid further stress on your joints, and help you to reduce your pain faster. Therefore, we recommend that you seek help to get an assessment and treatment.<br/><br/>Today, exercise paired with education to help you better understand your condition, is recommended as the first-line treatment for lower back pain. This is the treatment that research shows provides the best results and pain relief, and is recommended by international guidelines.<br/><br/>With Joint Academy, you get a complete and clinically proven treatment straight to your phone. This means that you can start feeling better from the comfort of your home! When you start your treatment, you will connect with a personal physical therapist who will support you and be available on-demand throughout your treatment. Your physical therapist will help you get started with an exercise program tailored to reduce your lower back pain and restore mobility. Your exercises only take 5 minutes a day.',
    4,
    99,
    0,
    3,
  ),
  formatResult(
    'But don\'t worry! There are ways to treat lower back pain that can get you back to doing what you love. At Joint Academy, you get the right treatment without the wait.',
    'Based on your answers, your lower back pain causes you a high degree of discomfort and the risk of having long-term problems is high. With the right care, you can feel better and get back to everyday life with less pain and more mobility. Therefore, we recommend that you seek help to get an assessment and treatment.<br/><br/>Today, exercise paired with education to help you better understand your condition, is recommended as the first-line treatment for lower back pain. This is the treatment that research shows provides the best results and pain relief, and is recommended by international guidelines.<br/><br/>With Joint Academy, you get a complete and clinically proven treatment straight to your phone. This means that you can start feeling better from the comfort of your home! When you start your treatment, you will connect with a personal physical therapist who will support you and be available on-demand throughout your treatment. Your physical therapist will help you get started with an exercise program tailored to reduce your lower back pain and restore mobility. Your exercises only take 5 minutes a day.',
    4,
    99,
    4,
    99,
  ),
];

export const questions = [
  formatYesNoQuestion(
    'question1',
    'Has your back pain spread down your leg(s) at some time in the last 2 weeks?',
  ),
  formatYesNoQuestion(
    'question2',
    'Have you had pain in the shoulder or neck at some time in the last 2 weeks?',
  ),
  formatYesNoQuestion(
    'question3',
    'Have you only walked short distances because of your back pain?',
  ),
  formatYesNoQuestion(
    'question4',
    'In the last 2 weeks, have you dressed more slowly than usual because of back pain?',
  ),
  formatYesNoQuestion(
    'question5',
    'Do you think that it’s safe for a person with a condition like yours to be physically active?',
    1,
    0,
    1,
  ),
  formatYesNoQuestion(
    'question6',
    'Do you often have worrying thoughts going through your mind?',
    1,
    0,
    1,
  ),
  formatYesNoQuestion(
    'question7',
    'Do you feel that your back pain is terrible and that it’s never going to get any better?',
    1,
    0,
    1,
  ),
  formatYesNoQuestion(
    'question8',
    'In general have you not enjoyed all the things that you used to enjoy?',
    1,
    0,
    1,
  ),
  formatQuestion(
    'question9',
    'Overall, how bothersome has your back pain been in the last 2 weeks?',
    [
      formatOption('Not at all', 0, 0, COL_SIZE_FULL, COL_SIZE_FULL),
      formatOption('Slightly', 0, 0, COL_SIZE_FULL, COL_SIZE_FULL),
      formatOption('Moderately', 0, 0, COL_SIZE_FULL, COL_SIZE_FULL),
      formatOption('Very much', 1, 1, COL_SIZE_FULL, COL_SIZE_FULL),
      formatOption('Extremely', 1, 1, COL_SIZE_FULL, COL_SIZE_FULL),
    ],
  ),
];

export const references = [
  'Hill JC, Dunn KM, Lewis M, et al. A primary care back pain screening tool: identifying patient subgroups for initial treatment. Arthritis Rheum. May 15 2008;59(5):632-641.',
  'Hill JC, Dunn KM, Main CJ, Hay EM. Subgrouping low back pain: a comparison of the STarT Back Tool with the Orebro Musculoskeletal Pain Screening Questionnaire. Eur J Pain. Jan 2010;14(1):83-89.',
  'Hill JC, Vohora K, Dunn KM, Main CJ, Hay EM. Comparing the STarT back screening tool’s subgroup allocation of individual patients with that of independent clinical experts. Clin J Pain. Nov-Dec 2010;26(9):783-787.',
  'Hill JC, Whitehurst DG, Lewis M, et al. Comparison of stratified primary care management for low back pain with current best practice (STarT Back): a randomised controlled trial. Lancet. Oct 29 2011;378(9802):1560-1571.',
  'Fritz JM, Beneciuk JM, George SZ. Relationship between categorization with the STarT Back Screening Tool and prognosis for people receiving physical therapy for low back pain. Physical therapy. May 2011;91(5):722-732.',
];
