import React from 'react';
import Layout from '@components/common/Layout';
import Seo from '@components/common/Seo';
import ScreeningTest from '@components/screeningTest/ScreeningTest';
import { resultSettings, riskSettings, questions, references } from '@quizData/us/en/back';
import { MARKET_US } from '@consts/market';
import { getScreeningPage } from '@/src/common/helpers/tracking';
import { JOINT_BACK } from '@consts/variant';
import { SOURCE_BACK_SCREENING } from '@consts/source';

const IndexPage = () => (
  <Layout
    language="en"
    segmentId={process.env.GATSBY_SEGMENT_SCREENING_2_PRODUCTION_US as string}
    page={getScreeningPage(questions)}
  >
    <Seo
      title="Screening - Back"
      description="Screening - Back"
      lang="en"
    />

    <section data-theme="white">
      <ScreeningTest
        source={SOURCE_BACK_SCREENING}
        variant={JOINT_BACK}
        market={MARKET_US}
        resultSettings={resultSettings}
        riskSettings={riskSettings}
        questions={questions}
        references={references}
        downloadUrl="https://app.adjust.com/9i342xh"
      />
    </section>
  </Layout>
);

export default IndexPage;
